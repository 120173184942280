import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Box,
  Button,
  ButtonProps,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Tab,
  TableContainer,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  handleDeleteCountry,
  handleDeleteDepartment,
  handleGetCompanies,
  handleGetCountries,
} from "../../api/accounts";
import { CompanyTable } from "../../components/Home/CompanyTable";
import UpdateCompanyModal from "../../components/Home/UpdateCompanyModal";
import UploadFileTabPanel from "../../components/Home/UploadFileTabPanel";
import { useAppDispatch, useAppSelector } from "../../store/reduxHooks";
import { getCookie, removeCookie } from "../../utils/cookie";
import { SimpleTable } from "../../components/Home/SimpleTable";
import AddCompanyModal from "../../components/Home/AddCompanyModal";
import SimpleModal from "../../components/Home/SimpleModal";
import RolesComponent from "../../components/Roles";
import { setCompanyData } from "../../store/companySlice";
import UserComponent from "../../components/Users";
import { handleGetDepartments } from "../../api/department";
import { DepartmentResponse } from "../../api/interface";
import { log } from "console";

const AuthCustomButton = styled(LoadingButton)<ButtonProps>(({ theme }) => ({
  borderRadius: 5,
  fontSize: 15,
  textTransform: "none",
}));

const HomePage = () => {
  const user = useAppSelector((state) => state.userSlice.user);
  type DeleteHandler = (id: string) => Promise<void>;
  const [countries, setCountries] = useState([]);
  const [departments, setDepartments] = useState<DepartmentResponse[]>([]);
  const [userId, setUserId] = useState("");
  const [selectedRow, setSelectedRow] = useState({
    first_name: "",
    last_name: "",
    company_name: "",
    action: "",
    email: "",
    company_id: "",
    department: "",
    id: "",
  });
  const [value, setValue] = React.useState("1");
  const [selectedCompany, setSelectedCompany] = useState({
    name: "",
  });
  const [selectedCountry, setSelectedCountry] = useState({
    name: "",
    company_id: "",
  });

  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDoneSaving, setIsDoneSaving] = useState(false);
  const [open, setOpenAddModal] = React.useState(false);
  const [openCompany, setOpenCompanyModal] = useState(false);
  const [openSimpleModal, setOpenSimpleModal] = useState(false);
  const [modal, setModal] = useState<any>({});
  const [dialogConfirmation, setDialogConfirmation] = useState(false);
  const [openConfirmation, setOpenConfirmation] = React.useState(false);
  const [openUpdateCompany, setOpenUpdateCompanyModal] = useState(false);
  const dispatch = useAppDispatch();
  const [currentDelete, setCurrentDelete] = useState<{
    handler: DeleteHandler;
    id: string;
  } | null>(null);

  const handleUpdateCompanyClick = (rowData: any) => {
    setSelectedRow({
      first_name: rowData.first_name,
      last_name: rowData.last_name,
      company_name: rowData.name,
      department: rowData.department,
      action: "update",
      email: "",
      company_id: "",
      id: rowData.id,
    });
    setOpenUpdateCompanyModal(true);
  };
  const handleUpdateCountryClick = (rowData: any) => {
    setSelectedCountry({
      name: rowData.name,
      company_id: rowData.company_id,
    });
    setModal({ type: "country", kind: "update" });
    setOpenSimpleModal(true);
  };

  const handleClickOpenConfirmation = () => {
    setOpenConfirmation(true);
  };

  const handleDeleteConfirmation = () => {
    setDialogConfirmation(true);
  };

  const handleCancelConfirmation = () => {
    setOpenConfirmation(false);
  };

  function handleDeleteEntity(deleteHandler: DeleteHandler, id: string) {
    setUserId(id);
    setCurrentDelete({ handler: deleteHandler, id: id });
    handleClickOpenConfirmation();
  }

  useEffect(() => {
    if (dialogConfirmation && currentDelete) {
      const fetchData = async () => {
        try {
          setIsLoading(true);
          await currentDelete.handler(currentDelete.id);
          setIsDoneSaving((prev) => !prev);
          setOpenAddModal(false);
        } catch (err) {
          setIsLoading(false);
          alert("Error while deleting");
          console.log(err);
        } finally {
          setIsLoading(false);
          setDialogConfirmation(false);
          setOpenConfirmation(false);
        }
      };

      fetchData();
    }
  }, [dialogConfirmation, currentDelete]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  useEffect(() => {
    const fetchAccount = async () => {
      try {
        setIsLoadingData(true);
        const [companies, countries, departments] = await Promise.all([
          handleGetCompanies(),
          handleGetCountries(),
          handleGetDepartments(),
        ]);
        dispatch(setCompanyData(companies.data.data));
        setCountries(countries.data.data);
        setDepartments(departments);

        setIsLoadingData(false);
      } catch (err) {
        setIsLoadingData(false);
        console.log(err);
      }
    };
    if (user) {
      fetchAccount();
    }
  }, [isDoneSaving, user, getCookie()]);

  function handleCreateClick(type: string) {
    switch (type) {
      case "account":
        setSelectedRow({
          first_name: "",
          last_name: "",
          company_name: "",
          action: "create",
          email: "",
          company_id: "",
          department: "",
          id: "",
        });
        setOpenAddModal(true);
        break;
      case "company":
        setSelectedCompany({
          name: "",
        });
        setOpenCompanyModal(true);
        break;
      case "country":
        setModal({ type: "country", kind: "create" });
        setOpenSimpleModal(true);
        break;
      case "department":
        setModal({ type: "department", kind: "create" });
        setOpenSimpleModal(true);
        break;

      default:
        break;
    }
  }

  async function handleDelete(id: string) {
    console.log("delete", id);

    // handleDeleteEntity(handleDeleteAccount, id);
    window.confirm("Do you   want to do this?");
  }

  async function handleDeleteDepartmentById(id: string) {
    handleDeleteEntity(handleDeleteDepartment, id);
  }

  async function handleDeleteCountryById(id: string) {
    handleDeleteEntity(handleDeleteCountry, id);
  }

  return (
    <Box sx={{ padding: 5 }}>
      {user && (
        <Typography sx={{ mb: 5 }}>
          Welcome {user.first_name} - {process.env.REACT_APP_ENVIRONMENT}
        </Typography>
      )}
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="tabs">
            <Tab label="Users" value="1" />
            <Tab label="Companies" value="2" />
            <Tab label="Countries" value="3" />
            <Tab label="Departaments" value="4" />
            <Tab label="Roles" value="5" />
            <Tab label="Upload file for mosaic trials" value="6" />
          </TabList>
        </Box>
        <RolesComponent value={"5"} />
        <UserComponent value={"1"} />

        {/*NEED TO REFACTOR ALL CODE BELOW THIS LINE*/}

        <TabPanel value={"2"}>
          <Button
            variant="contained"
            sx={{ marginTop: 4, marginBottom: 4 }}
            onClick={() => handleCreateClick("company")}
          >
            Add new company
          </Button>
          <TableContainer component={Paper} sx={{ maxHeight: 600 }}>
            {isLoadingData ? (
              <Box sx={{ textAlign: "center", marginTop: 5, marginBottom: 5 }}>
                <CircularProgress />
              </Box>
            ) : (
              <CompanyTable
                handleUpdateCompanyClick={handleUpdateCompanyClick}
                isLoading={isLoading}
                AuthCustomButton={AuthCustomButton}
                handleDelete={handleDelete}
              />
            )}
          </TableContainer>
        </TabPanel>
        <TabPanel value={"3"}>
          <Button
            variant="contained"
            sx={{ marginTop: 4, marginBottom: 4 }}
            onClick={() => handleCreateClick("country")}
          >
            Add new Country
          </Button>
          <TableContainer component={Paper} sx={{ maxHeight: 600 }}>
            {isLoadingData ? (
              <Box sx={{ textAlign: "center", marginTop: 5, marginBottom: 5 }}>
                <CircularProgress />
              </Box>
            ) : (
              <SimpleTable
                handleUpdateClick={handleUpdateCountryClick}
                isLoading={isLoading}
                tableData={countries}
                head={["Contry Name", ""]}
                AuthCustomButton={AuthCustomButton}
                handleDelete={handleDeleteCountryById}
              />
            )}
          </TableContainer>
        </TabPanel>
        <TabPanel value={"4"}>
          <Button
            variant="contained"
            sx={{ marginTop: 4, marginBottom: 4 }}
            onClick={() => handleCreateClick("department")}
          >
            Add new Department
          </Button>
          <TableContainer component={Paper} sx={{ maxHeight: 600 }}>
            {isLoadingData ? (
              <Box sx={{ textAlign: "center", marginTop: 5, marginBottom: 5 }}>
                <CircularProgress />
              </Box>
            ) : (
              <SimpleTable
                handleUpdateCompanyClick={handleUpdateCompanyClick}
                isLoading={isLoading}
                tableData={departments}
                head={["Department Name", ""]}
                AuthCustomButton={AuthCustomButton}
                handleDelete={handleDeleteDepartmentById}
              />
            )}
          </TableContainer>
        </TabPanel>
        <TabPanel value={"6"}>
          <UploadFileTabPanel />
        </TabPanel>

        {openUpdateCompany && (
          <UpdateCompanyModal
            open={openUpdateCompany}
            setOpen={setOpenUpdateCompanyModal}
            setIsDoneSaving={setIsDoneSaving}
            rowData={selectedRow}
          />
        )}

        {openCompany && (
          <AddCompanyModal
            open={openCompany}
            setOpen={setOpenCompanyModal}
            setIsDoneSaving={setIsDoneSaving}
            rowData={selectedCompany}
          />
        )}
        {modal && (
          <SimpleModal
            open={openSimpleModal}
            setOpen={setOpenSimpleModal}
            setIsDoneSaving={setIsDoneSaving}
            rowData={selectedCountry}
            kind={modal.kind}
            type={modal.type}
          />
        )}

        <Dialog open={openConfirmation} onClose={handleCancelConfirmation}>
          <DialogTitle>{"Delete Account?"}</DialogTitle>
          <DialogContent>
            <DialogContentText>Are you sure about this?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={handleCancelConfirmation}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={handleDeleteConfirmation}
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </TabContext>
    </Box>
  );
};

export default HomePage;
